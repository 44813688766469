<template>
  <div class="reportsGeneralTabWrapper pt-4">
    <v-tabs v-model="tab"
            color="text"
            background-color="transparent">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab v-for="item in items"
             :key="item"
             :ripple="false">
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="tab"
                  touchless>
      <v-tab-item :transition="false"
                  :reverse-transition="false">
        <div v-for="(info, index) in filteredInfo"
             :key="index"
             class="pl-3 mt-2">
          <v-row>
            <p class="subtitle-1 pt-6 mb-0 subtitle--text">{{info.title}}</p>
          </v-row>
          <v-row class="my-4">
            <v-col cols="12"
                   md="6"
                   lg="4"
                   v-for="(item, index) in info.data"
                   :key="index"
                   class="ma-0 pa-0">
              <div class="reportsGameDataWrapper">
                <p class="label body-2 mt-2">{{item.label}}
                  <v-tooltip transition="slide-x-transition"
                             right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon dark
                              small
                              color="disabled"
                              class="pl-2 mt-n1"
                              v-bind="attrs"
                              v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span v-html="item.info"></span>
                  </v-tooltip>
                </p>
                <p class="value caption subtitle--text">
                  {{selectedReportsGame[item.value] || '-'}}
                </p>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { findIndex, remove } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'reportsGameDetails',
  data() {
    const locale = this.$store.getters.translations;
    return {
      tab: null,
      items: [locale.general],
      generalInfo: [
        {
          title: '',
          value: 'info',
          data: [
            {
              label: locale.stake,
              value: 'betAmount',
              info: locale.stakeTooltip,
            }, {
              label: locale.totalWin,
              value: 'totalWinAmountWithPromotion',
              info: locale.totalWinTooltip,
            }, {
              label: locale.hold,
              value: 'holdAmountWithPromotion',
              info: locale.holdTooltip,
            },
          ],
        },
        {
          title: locale.game,
          value: 'game',
          data: [
            {
              label: locale.baseWin,
              value: 'winAmount',
              info: locale.baseWinTooltip,
            }, {
              label: locale.baseWin,
              value: 'baseWinAmount',
              info: locale.baseWinTooltip,
            }, {
              label: locale.bonusWin,
              value: 'bonusWinAmount',
              info: locale.bonusWinTooltip,
            }, {
              label: locale.jackpotWin,
              value: 'jackpotWinAmount',
              info: locale.jackpotWinTooltip,
            }, {
              label: locale.winAmountGems,
              value: 'winAmountGems',
              info: locale.winAmountGemsTooltip,
            }, {
              label: locale.multiplierWinAmount,
              value: 'multiplierWinAmount',
              info: locale.multiplierWinAmountTooltip,
            }, {
              label: locale.promotionWin,
              value: 'promotionWinAmount',
              info: locale.promotionWinTooltip,
            }, {
              label: locale.progressWinAmount,
              value: 'progressWinAmount',
              info: locale.progressWinTooltip,
            }, {
              label: locale.collectWin,
              value: 'collectWinAmount',
              info: locale.collectWinTooltip,
            },
          ],
        },
        {
          title: locale.gambleGame,
          value: 'gamble',
          data: [
            {
              label: locale.gambleWinAmount,
              value: 'gambleWinAmount',
              info: locale.gambleWinTooltip,
            },
          ],
        },
        {
          title: locale.bonusBuy,
          value: 'bonusBuy',
          data: [
            {
              label: locale.bonusBuyStake,
              value: 'bonusBuyAmount',
              info: locale.bonusBuyStakeTooltip,
            }, {
              label: locale.bonusBuyWinAmount,
              value: 'bonusBuyWinAmount',
              info: locale.bonusBuyWinTooltip,
            }, {
              label: locale.bonusBuyHoldAmount,
              value: 'bonusBuyHoldAmount',
              info: locale.bonusBuyHoldTooltip,
            },
          ],
        },
        {
          title: locale.collectBuy,
          value: 'collectBuy',
          data: [
            {
              label: locale.collectBuyStake,
              value: 'collectBuyAmount',
              info: locale.collectBuyStakeTooltip,
            }, {
              label: locale.collectBuyWinAmount,
              value: 'collectBuyWinAmount',
              info: locale.collectBuyWinTooltip,
            }, {
              label: locale.collectBuyHoldAmount,
              value: 'collectBuyHoldAmount',
              info: locale.collectBuyHoldAmount,
            },
          ],
        },
        {
          title: locale.progressBuy,
          value: 'progressBuy',
          data: [
            {
              label: locale.progressStake,
              value: 'progressBuyAmount',
              info: locale.progressStakeTooltip,
            }, {
              label: locale.progressBuyWinAmount,
              value: 'progressBuyWinAmount',
              info: locale.progressBuyWinTooltip,
            }, {
              label: locale.progressHoldAmount,
              value: 'progressBuyHoldAmount',
              info: locale.progressHoldTooltip,
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'selectedReportsGame',
    ]),
    filteredInfo() {
      if (this.selectedReportsGame.gameType === 'PrizeDrop') {
        remove(this.generalInfo[findIndex(this.generalInfo, ['value', 'game'])].data,
          (info) => info.value === 'bonusWinAmount'
            || info.value === 'jackpotWinAmount'
            || info.value === 'winAmount');
        remove(this.generalInfo, (info) => info.value === 'gamble');
      } else {
        remove(this.generalInfo[findIndex(this.generalInfo, ['value', 'game'])].data,
          (info) => info.value === 'winAmountGems'
            || info.value === 'multiplierWinAmount'
            || info.value === 'baseWinAmount');
      }
      return this.generalInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
.reportsGeneralTabWrapper {
  .v-tab {
    font-weight: 300;
  }
}
.reportsGameDataWrapper {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 4px;
  margin: 12px 12px 0 0;
  .label,
  .value {
    margin: 3px 10px;
  }
}
</style>
