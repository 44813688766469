import {
  forEach,
  isEqual,
  join,
  trim,
} from 'lodash';

export const isEntered = (value, label) => ((amount) => (!!amount && value !== 0)
  || label);
export const isSmaller = (value, label) => ((amount) => (amount && Number(amount) < Number(value))
  || label);
export const isLarger = (value, label) => ((amount) => (amount && Number(amount) > Number(value))
  || label);
export const isDecimal = (label) => ((amount) => /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(amount)
  || label);
export const noDecimal = (label) => ((amount) => /^[0-9]+$/.test(amount)
  || label);
export const isRatio = (value1, value2, label) => ((amount) => (amount && value2 / value1 >= 10)
  || label);
export const isInputType = (values, label) => ((file) => (file ? values.includes(file.type)
  || label : true));
export const nameLength = (value, label) => ((amount) => (amount
  && trim(value).length >= 4
  && trim(value).length <= 40)
  || label);
export const idLength = (label) => ((amount) => (amount ? amount.length <= 120 : true)
  || label);
export const arrayLength = (label) => ((amount) => (amount && amount.length >= 1)
  || label);
export const isUri = (label) => ((amount) => {
  const pattern = /(https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_/0-9\-#.]*)\??([a-z_/0-9\-#=&]*)/g;
  return pattern.test(amount) || label;
});
export const isValidAmount = (value, isPromoTypeRounds, labelMin, labelMax) => ((amount) => {
  const games = [];
  let label = '';
  if (value) {
    forEach(value, (item) => {
      const numOfPaylines = isEqual(item.paylineType, 'Fixed') ? item.numOfPaylines : 20;
      if ((amount / numOfPaylines) < item.settings.minBetAmount) {
        games.push(item.displayName);
        label = labelMin;
      } if (isPromoTypeRounds && (amount / numOfPaylines) > item.settings.maxBetAmount) {
        games.push(item.displayName);
        label = labelMax;
      }
    });
  }
  return games.length ? label.concat(join(games, ', ')) : true;
});
