import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.name != 'unauthorized')?_c(VNavigationDrawer,{staticClass:"navigationWrapper",attrs:{"permanent":"","app":"","width":"100","color":"background"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('SettingsMenu')]},proxy:true}],null,false,3445772336),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(_vm.getEnv)?_c('div',{staticClass:"envBar"},[_vm._v(" "+_vm._s(_vm.getEnv)+" ")]):_vm._e(),_c(VListItem,{attrs:{"to":"/"}},[_c(VRow,{staticClass:"my-2"},[_c(VListItemAvatar,{staticClass:"mx-auto"},[_c(VImg,{attrs:{"src":require("./../../public/assets/logo.png")}})],1)],1)],1),_c(VList,_vm._l((_vm.navigation),function(item){return _c(VListItem,{key:item.title,class:_vm.getNavigationStyle(item.link),attrs:{"link":"","two-line":""},on:{"click":function($event){return _vm.getNavigationPath(item.link)}}},[_c(VListItemContent,{staticClass:"text-center"},[_c(VIcon,{staticClass:"mb-1",attrs:{"color":"disabled"}},[_vm._v(_vm._s(item.icon))]),_c('span',{staticClass:"navigationTitle disabled--text"},[_vm._v(_vm._s(_vm.translations[item.title]))])],1)],1)}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }