<template>
  <div>
    <v-row class="ma-0">
      <v-col cols="12"
             xs="12"
             sm="8"
             md="7"
             lg=8>
        <p class="pt-2 mb-2 subtitle--text">
          <LocalizedLabel>dateAndTime</LocalizedLabel>
        </p>
        <p class="subtitle-2 pb-4">{{gameDetails.createdAt}}</p>
        <div v-if="getWindowSize">
          <v-divider></v-divider>
          <transition-group name="slide"
                            key="game">
            <v-card flat
                    width="100%"
                    v-for="(item) in filteredGameData"
                    :key="item.label">
              <v-card-subtitle class="caption ml-0 pl-0">
                {{item.label}}
              </v-card-subtitle>
              <div class="ml-0 mt-n3 caption">
                <span>{{gameDetails[item.value]}}</span>
              </div>
              <v-divider class="mt-4"></v-divider>
            </v-card>
          </transition-group>
          <v-card-actions class="justify-center">
            <v-btn text
                   small
                   class="my-2 subtitle--text"
                   @click="showMore = !showMore">
              <span>{{ showMore ? translations.showLess : translations.showMore }}</span>
            </v-btn>
          </v-card-actions>
        </div>
        <v-card id="reelsFrameContainer"
                class="cardWrapper"
                elevation="0"
                v-if="hasGameDetails && hasRoundPreview">
          <v-row class="fill-height"
                 align-content="center"
                 justify="center"
                 v-if="!isLoaded">
            <v-progress-linear color="primary"
                               indeterminate
                               rounded
                               height="6">
            </v-progress-linear>
          </v-row>
          <transition name="fade">
            <RouletteRoundPreview :details="gameDetails"
                                  v-if="isRoulette && isLoaded">
            </RouletteRoundPreview >
            <iframe v-else
                    :src="roundPreviewUrl"
                    @load="onLoad"
                    ref="iframe"
                    id="iframe"
                    v-show="isLoaded"
                    scrolling="yes"
                    title="roundPreview">
            </iframe>
          </transition>
        </v-card>
        <v-row class="pa-0 ma-0 pt-4"
               v-if="hasGameDetails">
          <v-col cols="12"
                 sm="6"
                 v-for="(item, index) in dataModelRoundData"
                 :key="index"
                 class="roundList">
            <div class="roundDataWrapper">
              <p class="label caption">{{item.label}}</p>
              <p class="value caption subtitle--text">{{gameDetails[item.value]}}</p>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical
                 class="mt-n4 px-1 ma-0 verticalDivider"
                 v-if="!getWindowSize"></v-divider>
      <v-col cols="12"
             sm="4"
             md="5"
             lg="4"
             class="pa-0 ml-n3 pl-3 mt-n4"
             v-if="hasGameDetails && !getWindowSize">
        <v-card flat
                width="100%"
                v-for="(item, index) in filteredGameData"
                :key="index">
          <v-card-subtitle class="caption ml-1">
            {{item.label}}
          </v-card-subtitle>
          <div class="ml-5 mt-n3 caption">

            <span>
              {{gameDetails[item.value]}}
            </span>
          </div>
          <v-divider class="mt-4 ml-n3"></v-divider>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="mx-3"></v-divider>
    <v-row class="ma-0 pt-3 px-3">
        <v-data-table
                  class="multiColumnsTable"
                  :headers="transactionsHeaders"
                  :items="mappedTransactionsData"
                  fixed-header
                  :loading="loading"
                  :items-per-page="-1"
                  :hide-default-footer="true">
        </v-data-table>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  assign,
  filter,
  includes,
  isEmpty,
  isEqual,
  keys,
  map,
  merge,
  take,
  unset,
} from 'lodash';
import { isoToDate, roundDecimals } from '@/utility';
import RouletteRoundPreview from './RouletteRoundPreview';

export default {
  components: { RouletteRoundPreview },
  name: 'gameRoundDetails',
  data() {
    const locale = this.$store.getters.translations;
    return {
      isLoaded: false,
      gameDetails: {},
      showMore: false,
      windowWidth: window.innerWidth,
      roundTransactionsData: [],
      roundTransactionsPagination: {},
      transactionsPage: 1,
      roundData: [
        {
          label: locale.freeLabel,
          value: 'isFree',
        }, {
          label: locale.lines,
          value: 'numOfActivePaylines',
        }, {
          label: locale.stake,
          value: 'betAmount',
        }, {
          label: locale.hold,
          value: 'holdAmount',
        }, {
          label: locale.totalWin,
          value: 'totalWinAmount',
        }, {
          label: locale.jackpotWin,
          value: 'jackpotWinAmount',
        }, {
          label: locale.baseGameWin,
          value: 'winAmount',
        }, {
          label: locale.bonusGameWin,
          value: 'bonusWinAmount',
        }, {
          label: locale.multiplier,
          value: 'multiplier',
        }, {
          label: locale.bonusMultiplier,
          value: 'bonusMultiplier',
        }, {
          label: locale.gambleWinAmount,
          value: 'gambleWinAmount',
        }, {
          label: locale.bonusPick,
          value: 'bonusWonPrize',
        }, {
          label: locale.promotionName,
          value: 'promotionDisplayName',
        }, {
          label: locale.bonusPickIndex,
          value: 'bonusPrizePicks',
        }, {
          label: locale.bonusPick,
          value: 'bonusPrizeWinAmounts',
        }, {
          label: locale.itemsPickedCount,
          value: 'itemsPickedCount',
        }, {
          label: locale.winAmountBase,
          value: 'winAmountBase',
        }, {
          label: locale.baseMultiplier,
          value: 'winAmountMultiplier',
        }, {
          label: locale.winAmountGems,
          value: 'winAmountGems',
        }, {
          label: locale.multiplierWinAmount,
          value: 'multiplierWinAmount',
        }, {
          label: locale.bonusBuyWinAmount,
          value: 'bonusBuyWinAmount',
        }, {
          label: locale.collectMultiplier,
          value: 'collectMultiplier',
        }, {
          label: locale.collectBuyWinAmount,
          value: 'collectBuyWinAmount',
        }, {
          label: locale.collectWin,
          value: 'collectWinAmount',
        }, {
          label: locale.powerNumberWinAmount,
          value: 'powerNumberWinAmount',
        }, {
          label: locale.progressBuyWinAmount,
          value: 'progressBuyWinAmount',
        }, {
          label: locale.progressWinAmount,
          value: 'progressWinAmount',
        },
      ],
      gameData: [
        {
          label: locale.game,
          value: 'gameName',
        }, {
          label: locale.player,
          value: 'playerUsername',
        }, {
          label: locale.rtp,
          value: 'theoreticalRtp',
        }, {
          label: locale.symbol,
          value: 'numOfSymbols',
        }, {
          label: locale.company,
          value: 'companyName',
        },
      ],
      roundDetailsLocalisation: {
        roundsResultsLabel: locale.roundsResultsLabel,
        selectionLabel: locale.selectionLabel,
        stakeLabel: locale.stake,
        winLabel: locale.winLabel,
      },
      transactionsHeaders: [
        {
          text: locale.transactionId,
          value: 'id',
          align: 'start',
          sortable: true,
        },
        {
          text: locale.type,
          value: 'transactionType',
          align: 'start',
          sortable: true,
        },
        {
          text: locale.source,
          value: 'sourceType',
          align: 'start',
          sortable: true,
        },
        {
          text: locale.amount,
          value: 'amount',
          align: 'end',
          sortable: true,
        },
        {
          text: locale.date,
          value: 'createdAt',
          align: 'end',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      'loadData',
      'setDetailsModalConfig',
    ]),
    onLoad() {
      this.isLoaded = true;
    },
    getSlotDataModel() {
      const roundDataValues = [
        'betAmount',
        'bonusBuyWinAmount',
        'bonusMultiplier',
        'bonusPrizePicks',
        'bonusPrizeWinAmounts',
        'bonusWinAmount',
        'bonusWonPrize',
        'collectBuyWinAmount',
        'collectMultiplier',
        'collectWinAmount',
        'gambleWinAmount',
        'holdAmount',
        'isFree',
        'jackpotWinAmount',
        'multiplier',
        'numOfActivePaylines',
        'promotionDisplayName',
        'progressBuyWinAmount',
        'progressWinAmount',
        'totalWinAmount',
        'winAmount',
      ];
      return {
        roundData: filter(this.filteredRoundData,
          (data) => roundDataValues.indexOf(data.value) >= 0),
        gameData: this.gameDataValues,
      };
    },
    getSlingshotDataModel() {
      const roundDataValues = [
        'betAmount',
        'holdAmount',
        'isFree',
        'jackpotWinAmount',
        'multiplier',
        'totalWinAmount',
      ];
      const gameDataValues = ['gameName', 'playerUsername', 'companyName'];
      return {
        roundData: filter(this.filteredRoundData,
          (data) => roundDataValues.indexOf(data.value) >= 0),
        gameData: filter(this.filteredGameData,
          (data) => gameDataValues.indexOf(data.value) >= 0),
      };
    },
    getPrizeDropDataModel() {
      const roundDataValues = [
        'betAmount',
        'holdAmount',
        'isFree',
        'itemsPickedCount',
        'multiplier',
        'multiplierWinAmount',
        'totalWinAmount',
        'winAmountBase',
        'winAmountGems',
        'winAmountMultiplier',
      ];
      const gameDataValues = ['gameName', 'playerUsername', 'companyName'];
      return {
        roundData: filter(this.filteredRoundData,
          (data) => roundDataValues.indexOf(data.value) >= 0),
        gameData: filter(this.filteredGameData,
          (data) => gameDataValues.indexOf(data.value) >= 0),
      };
    },
    getRouletteDataModel() {
      const roundDataValues = [
        'betAmount',
        'holdAmount',
        'isFree',
        'totalWinAmount',
        'winAmount',
        'jackpotWinAmount',
        'powerNumberWinAmount',
        'bonusWinAmount',
      ];
      const gameDataValues = ['gameName', 'playerUsername', 'companyName'];

      return {
        roundData: filter(this.filteredRoundData,
          (data) => roundDataValues.indexOf(data.value) >= 0),
        gameData: filter(this.filteredGameData,
          (data) => gameDataValues.indexOf(data.value) >= 0),
      };
    },
    handleTransactionsScroll() {
      const element = document.getElementById('detailsModalId');
      if (element.scrollTop === element.scrollHeight - element.clientHeight
        && this.transactionsPage < this.roundTransactionsPagination.pages) {
        this.transactionsPage += 1;
        this.getTransactions();
      }
    },
    async getTransactions() {
      const response = await this.loadData({
        path: `tenants/${this.$route.params.companyId}/games/${this.$route.params.gameId}/rounds/${this.gameDetails.id}/transactions?size=5&page=${this.transactionsPage}`,
      });

      if (response) {
        this.roundTransactionsData = [...this.roundTransactionsData, ...response.data];
        this.roundTransactionsPagination = response.pagination;
      }
    },
  },
  computed: {
    ...mapGetters([
      'detailsModalToggler',
      'isMobile',
      'loading',
      'selectedRound',
      'translations',
    ]),
    roundDetailsLocalisations() {
      return keys(this.roundDetailsLocalisation).map((key) => `${key}=${this.roundDetailsLocalisation[key]}`).join('&');
    },
    roundPreviewUrl() {
      return `${process.env.VUE_APP_ROUND_PREVIEW_URL}?roundId=${this.selectedRound.id}&tenantGameId=${this.$route.params.gameId}&${this.roundDetailsLocalisations}`;
    },
    filteredGameData() {
      if (!this.showMore && this.getWindowSize) {
        return take(filter(this.gameData, (game) => this.gameDetails[game.value]), 2);
      }
      return filter(this.gameData, (game) => this.gameDetails[game.value]);
    },
    filteredRoundData() {
      if (isEqual(this.gameDetails.multiplier, 1)) {
        unset(this.gameDetails, ['multiplier']);
      } if (isEqual(this.gameDetails.bonusMultiplier, 1)) {
        unset(this.gameDetails, ['bonusMultiplier']);
      } if (isEqual(Number(this.gameDetails.jackpotWinAmount), 0)) {
        unset(this.gameDetails, ['jackpotWinAmount']);
      } if (includes(['FreeRounds', 'PickPrizeLevels'], this.gameDetails.bonusType)) {
        unset(this.gameDetails, ['bonusWonPrize']);
      }

      return filter(this.roundData, (round) => this.gameDetails[round.value]);
    },
    hasGameDetails() {
      return !isEmpty(this.gameDetails);
    },
    dataModelRoundData() {
      return this[`get${this.gameDetails.gameType}DataModel`]().roundData;
    },
    dataModelGameData() {
      return this[`get${this.gameDetails.gameType}DataModel`]().gameData;
    },
    getWindowSize() {
      return this.windowWidth < 600;
    },
    hasRoundPreview() {
      return !(this.gameDetails.gameType === 'PrizeDrop');
    },
    mappedTransactionsData() {
      if (this.roundTransactionsData) {
        const list = map(this.roundTransactionsData, (data) => ({
          ...data,
          createdAt: isoToDate(data.createdAt),
          amount: roundDecimals(data.amount),
        }));
        return list;
      }
      return [];
    },
    isRoulette() {
      return this.gameDetails.gameType === 'Roulette';
    },
  },
  watch: {
    detailsModalToggler(newValue) {
      if (!newValue) {
        this.setDetailsModalConfig({
          roundDetails: false,
        });
      }
    },
  },
  mounted() {
    document.getElementById('detailsModalId').addEventListener('scroll', this.handleTransactionsScroll);
  },
  async created() {
    const response = await this.loadData({
      path: `tenants/${this.$route.params.companyId}/games/${this.$route.params.gameId}`,
    });
    const payload = {
      companyName: this.$route.params.companyName,
      gameName: this.$route.params.gameName,
    };
    if (response) {
      this.gameDetails = assign(response.data, merge(this.selectedRound, payload));
      this.getTransactions();
    }
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>

<style lang="scss" scoped>
.roundList {
  &:nth-child(even) {
    padding: 8px 0px 8px 8px;
  }
  &:nth-child(odd) {
    padding: 8px 8px 8px 0px;
  }
  @media screen and (max-width: 600px) {
    padding: 8px 0px !important;
  }
  .roundDataWrapper {
    border: 1px solid rgba(128, 128, 128, 0.3);
    border-radius: 4px;
    .label,
    .value {
      margin: 3px 10px;
    }
  }
}
.cardWrapper {
  scroll-behavior: auto;
  width: 100%;
  aspect-ratio: 5.2/3.2;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    border: none;
    overflow: hidden !important;
    scroll-behavior: auto;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.verticalDivider {
  margin-bottom: -44px;
}
.slide-leave-active,
.slide-enter-active {
  transition: 0.2s;
}
.slide-enter {
  transform: scaleY(1);
}
.slide-leave-to {
  transition: 0.1s;
  transform: scaleY(0);
}

::v-deep .multiColumnsTable {
  width: 100%;
}
</style>
